import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Live Video",
  "path": "/Web_User_Interface/1080p_Series/Live_Video/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Here you need to input the sender, the receivers, the subject as well as optional a short email text with a maximum 64 characters. The sender address should be in accordance with the SMTP server settings, though some email provider let you choose this field freely. If you want to send the email to more than one address, simply separate the addresses with a semicolon.",
  "image": "./WebUI_1080p_SearchThumb_System_WebUI.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_System_WebUI.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Live Video' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='IP Camera Live Video' image='/images/Search/QI_SearchThumb_WebUI_1080p.png' twitter='/images/Search/QI_SearchThumb_WebUI_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Live_Video/' locationFR='/fr/Web_User_Interface/1080p_Series/Live_Video/' crumbLabel="Live Video" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#live-video",
        "aria-label": "live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Live Video`}</h2>
    <p>{`When you access your camera's web user interface, you will be greeted with the live video window. Please refer to our `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Live_Video/"
      }}>{`Plugin Guide`}</a>{` if you cannot see the video stream.`}</p>
    <h3 {...{
      "id": "settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#settings",
        "aria-label": "settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Settings`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/84712e69e273086e3bebb50f58792d61/8b936/1080p_Settings_Live_Video.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADAElEQVQoz02SW0jTcRzFf0TRRUwrDUM3L8tLiZUa6ZyVl/JvU1eakq6Zs2k5nW1pZayii7N6SKHbW1ZU4oMQzgwjsiioh6yILmBQD4FZOufc9p9O1+97wvKhA4fz9uHAOQyBAcCyQCAwkMAY+PXr027A53KMw+FwkNPpIo8owuedIsxpemYGbreHRHESkx4PDQwMiHtK1VOVumqRjVt7YO/ugaPbyn19fXh17+5baWT4kGJDMvLTs0mbXwyNUEatlfthvXSeHnf1krWzE62WRrradBzNegOZSiv53swcUm5M4WzY48UPcQpjXi+fAdBl7f3EGBsKD5UhM1FBRamZZFAa0KxuoTPqCjLmqXBYrSaLbjduHTPS+arjOFGgQZOwBUVJm8Bso2MYGbFhbHSUz3i9sN7v+ioo1ontbS24pq/nWTFJOKoxUHW+CbqifThQpqcGTQXqC8voZGktWqsr6bLeSIWCnhLXyYn9tNkxNGKH3T7OvdPT6O/rebM+zL9/dUTUiEa+BuXZKTxOIkNx8hoqzMgiQSghVUYm7UxLR/+5CvpwJoM+tpVQr6WJzPlyMJt9HKP2vwP4RNGNgTfvrYwxP8bYwYDFC70WXR6Ejcl8jyIBdQWpFCmV8rCQUNLuysaR8m2kjI+lU7lSPKoKof7qtcQmJiYwZ5/odmNw8EsHY2z+6SZTEmOsJ02yCO0HEn4/bM4gsyqeIiVhfGfOdqox1CIyOoFiZGspKCiUIoKXU0NKMGdOpxOzngXO3uPjp8/dhbuL0959+LxeFr9p3+ZoP9TlReFCSSyvyVaQfPUqbm40krZ8L2QSKcXFxlFw0Ary91uCrCh/zDX8B3S5XBgeHr4RLoteaTLWb6lLW9qeG82uMcaebItY6nytCsDBmCWQK5U8Lj2VpDIpBUtCfIsWL+DzGLspSNnL/xv+nk37mM36zYWE22ernr64UnK34/n3rfpy9YN7uTum7gg5P8/qtM9K67W/Dl08goYLR0ltqvAVFKkmG8yNN8yWllN/AHO+2ojUnmxyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84712e69e273086e3bebb50f58792d61/e4706/1080p_Settings_Live_Video.avif 230w", "/en/static/84712e69e273086e3bebb50f58792d61/d1af7/1080p_Settings_Live_Video.avif 460w", "/en/static/84712e69e273086e3bebb50f58792d61/7f308/1080p_Settings_Live_Video.avif 920w", "/en/static/84712e69e273086e3bebb50f58792d61/49c7f/1080p_Settings_Live_Video.avif 1368w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/84712e69e273086e3bebb50f58792d61/a0b58/1080p_Settings_Live_Video.webp 230w", "/en/static/84712e69e273086e3bebb50f58792d61/bc10c/1080p_Settings_Live_Video.webp 460w", "/en/static/84712e69e273086e3bebb50f58792d61/966d8/1080p_Settings_Live_Video.webp 920w", "/en/static/84712e69e273086e3bebb50f58792d61/338ac/1080p_Settings_Live_Video.webp 1368w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84712e69e273086e3bebb50f58792d61/81c8e/1080p_Settings_Live_Video.png 230w", "/en/static/84712e69e273086e3bebb50f58792d61/08a84/1080p_Settings_Live_Video.png 460w", "/en/static/84712e69e273086e3bebb50f58792d61/c0255/1080p_Settings_Live_Video.png 920w", "/en/static/84712e69e273086e3bebb50f58792d61/8b936/1080p_Settings_Live_Video.png 1368w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/84712e69e273086e3bebb50f58792d61/c0255/1080p_Settings_Live_Video.png",
              "alt": "Web User Interface - 1080p Series - Live_Video",
              "title": "Web User Interface - 1080p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<strong parentName="p">{`Settings`}</strong>{` in the top right to open the settings menu. The settings menu contains all the settings that are described in our `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`Web User Interface Guide`}</a>{`.`}</p>
    <h3 {...{
      "id": "ptz",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ptz",
        "aria-label": "ptz permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PTZ`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/159088ee631219ab5d6dad14a2468144/f0811/1080p_View_Relay.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADGUlEQVQozx3P20+TdxwH4N+diTEiJcA8UKEFG202FUtoBz0AAaS0QDsqxdYKSFZR67SoLB3VgEC3FXCQiDsQFg8JGg93burdMo0amVLeHlD2QtD05ZDBwAVPab+fJV48f8DDZmZn5iORMIVDYQS5EL0Yn8A0P4nX83OIAXgfi2F+dh5CdAbRl1HMRQVEp1+B/3sKU1PTNPvfWxJev8XcynsIy2/AcnZl30tOFGGnZGvclK8js1oPu8ZE/Q31GO7qoKv9gxjs9cHf6kS/240WewNcpr1oLNCRQaXGwDMBg+NL9GtkEReCC2BiifzPVatFcU2OOl6q0FGtppzO2r9Hm72NvFYrHTaYybVnD/UetOGX5mZqr/fAa7SSS6uEXllAfX8JNBD6Fz8GF6h/bAHMXKYKuGxG3Br4gVw6Pe1W5ONYzVfkKKnDPpMDTfZDOG6zw2mooS7HEZxvsuGC20P6wkYoVUXUPSJQT2AR3c9mP2L6/O3D8i3SB2mipA8t5dkozP6MlFlZZFfKqWJ3FRlKK6ko93Paq83DE/9+enRKQYEeG/1++hA8luKP5b7gEvyjAvyBGTBD9REtYyyRMXZTJdsIb0M1qTKl1KTPIat2O61P/YSkael0vM4Ip1mDcrkMbUYx7jhEdPtwDvkeRXEu8A/d4SbpLseD1dr3K4YuXZbLMjNqGWOvTujWYbhFHb/dmksWpZQy08VUZ7XSvgMO2pQmI5l0KyWsS6VtG1Pibl1GvGdEwLmxBUTCIZoIB8Faz7Q3ejwezbXf/lBsSE4aqtolgtu0BX7TNpjyVKSTi8nX2YmywkLIJJmQSrOwWbwJ6jwVvvyiFFceR+hGMEpcKIRgKAT2re/s5q87ejIsuekl9XI2JF7LLjLGRr3KpHf3K9agUpIQ11gsJNnxKaVLxZSQnBhLShHF9CXap9WKDTz3dAQTkTA4jiOO48CWlxbLHzzh1Te9xWOjtzqcp777qeZYlTF8vaDg3c8lxdPOWutDa/OBlaO+E9R02kXGOvOHsgr9SvM3J/vOdLT7x58/J35yCjzPE8/z+B/6yszU02o3aAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/159088ee631219ab5d6dad14a2468144/e4706/1080p_View_Relay.avif 230w", "/en/static/159088ee631219ab5d6dad14a2468144/d1af7/1080p_View_Relay.avif 460w", "/en/static/159088ee631219ab5d6dad14a2468144/7f308/1080p_View_Relay.avif 920w", "/en/static/159088ee631219ab5d6dad14a2468144/16517/1080p_View_Relay.avif 1367w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/159088ee631219ab5d6dad14a2468144/a0b58/1080p_View_Relay.webp 230w", "/en/static/159088ee631219ab5d6dad14a2468144/bc10c/1080p_View_Relay.webp 460w", "/en/static/159088ee631219ab5d6dad14a2468144/966d8/1080p_View_Relay.webp 920w", "/en/static/159088ee631219ab5d6dad14a2468144/2c416/1080p_View_Relay.webp 1367w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/159088ee631219ab5d6dad14a2468144/81c8e/1080p_View_Relay.png 230w", "/en/static/159088ee631219ab5d6dad14a2468144/08a84/1080p_View_Relay.png 460w", "/en/static/159088ee631219ab5d6dad14a2468144/c0255/1080p_View_Relay.png 920w", "/en/static/159088ee631219ab5d6dad14a2468144/f0811/1080p_View_Relay.png 1367w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/159088ee631219ab5d6dad14a2468144/c0255/1080p_View_Relay.png",
              "alt": "Web User Interface - 1080p Series - Live_Video",
              "title": "Web User Interface - 1080p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If your camera model has a Pan, Tilt and Zoom camera head, just hover your mouse pointer over the live image, to display the control pad on the left to pan and tilt your camera. Cameras, like the IN-9020 Full HD, additionally have a varifocal auto-zoom lense, that is zoomed and focussed by the Button array on the right.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3ed8299ee215236c7521a7c0175b2363/7af19/1080p_View_PTZ.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADCklEQVQoz3WOf0yUdQCHvy61U7PGhsaAOzpOD0h+yInKAZcc1yWYyuEd76mnoMJk/gB1WgE3ZGkWZXYyh4pWw/rHOU2HPzd/JCKhfyAevG++eGdrtbW2kvSGgsD7+bTV+rNne/b8+4jIi3E+JwGMksN/kKN/keNPSS1C4jn/B/zXpyNjePxshE9GxvDn0DDFrv1Hf/fV7mfgo93oCGzCxc+2sv3jbeg+tIP32vbi/q1uDvTdY0/HBQY7bvBG+3lePdvOa6dO4nTbCX4Z/I1fqU/wjTrIY8pjiomTpl8XE3TMMM/VFmXkwbWwCPWePWzw+rF79RpUF0uolkrZtMGDYzuq2eDbyQ8Wl6LGNo+FaVn4/PYjtKoRHpEHcbBvkMKekx7cVu7mdy0HtY15hSjJKWBdWT3WL9nEytJybvRW8r21Zdzi8qHRt5WBqgq2VO/CMkclc60O7Ov8CV/0D/JqMISbfQMUjsw3zlrnZ4QtJiP9xdnMfjMFVrMZvuxUuN91Y0lRKVY4HHDn2XinqRI9/mz0BspxrrEeO4vzeeCHR2x5EMH9fpkhpY8iJiE/SwhhFEJ870xPoL9ipWadZUKZ3QKPzYK42Dgkxhmw3VfEurX5kCxJDLgTeLMqFp01FpzqCqJd+ZVBWUF/v0wRZ0rWX75yKSnqtVcrhBCROmcMDm9eoF35MAe+3NkwGPTwLF2GNRXrYJyVCpMxBTOjY5H8epTWWGDQfrzbxdCASkVRoCgKRdXmGk9tXb0z0NJq0+mmnfDOj2LTujlsXjkXBelZWm5SvPbpvj2QXMuZqDcgyZzMGdHRmDpFR/vsaPZ0d0J+oFKW5X8UDQ21+ubWr1M9iwvs6zMnnrEYJ18XQoT2OvVj1zwz6E6O4dveVTSmzUGiMQGxhnhtytSXNd1L4qKU+srPd7pu8WEoDFmW/z0cH3sWf6D528zjG0wdJ/3vSGZbSaErM6PneIp5qM1i/kVatPCuvdw15K5ZjRVVkmZd/tZo2rz0YcdS5yclXun93t7ekXA4TFVVoaoq/wbQhNcCMoHCYwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ed8299ee215236c7521a7c0175b2363/e4706/1080p_View_PTZ.avif 230w", "/en/static/3ed8299ee215236c7521a7c0175b2363/d1af7/1080p_View_PTZ.avif 460w", "/en/static/3ed8299ee215236c7521a7c0175b2363/7f308/1080p_View_PTZ.avif 920w", "/en/static/3ed8299ee215236c7521a7c0175b2363/54dce/1080p_View_PTZ.avif 1359w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3ed8299ee215236c7521a7c0175b2363/a0b58/1080p_View_PTZ.webp 230w", "/en/static/3ed8299ee215236c7521a7c0175b2363/bc10c/1080p_View_PTZ.webp 460w", "/en/static/3ed8299ee215236c7521a7c0175b2363/966d8/1080p_View_PTZ.webp 920w", "/en/static/3ed8299ee215236c7521a7c0175b2363/fc170/1080p_View_PTZ.webp 1359w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3ed8299ee215236c7521a7c0175b2363/81c8e/1080p_View_PTZ.png 230w", "/en/static/3ed8299ee215236c7521a7c0175b2363/08a84/1080p_View_PTZ.png 460w", "/en/static/3ed8299ee215236c7521a7c0175b2363/c0255/1080p_View_PTZ.png 920w", "/en/static/3ed8299ee215236c7521a7c0175b2363/7af19/1080p_View_PTZ.png 1359w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3ed8299ee215236c7521a7c0175b2363/c0255/1080p_View_PTZ.png",
              "alt": "Web User Interface - 1080p Series - Live_Video",
              "title": "Web User Interface - 1080p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Clicking on Positions, in the top centre of the live image, opens the menu to store camera positions and recall them later. These positions can be used as `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`Alarm or Start Position`}</a>{`. They also make it much easier to control your PTZ camera with our smartphone app.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4b4d451ea9a3eed95577e26aba41fc92/ee515/1080p_View_PTZ_Save.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "9.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAdElEQVQI123BsQ3CMBAAwAzoTTwBqSJFqZItooiGAdLjFtksQIciWf9vyQjzQP7NAtw1AEBEiJhz5ldR+VSVt8he/1NV77211hjTbBEBKaXEz0e40XK+ny5E87G2B+m67zBc15XHUft+n6YCUJhjjCEE59wPrhJmpy9OpCgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b4d451ea9a3eed95577e26aba41fc92/e4706/1080p_View_PTZ_Save.avif 230w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/d1af7/1080p_View_PTZ_Save.avif 460w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/7f308/1080p_View_PTZ_Save.avif 920w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/dc388/1080p_View_PTZ_Save.avif 1269w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4b4d451ea9a3eed95577e26aba41fc92/a0b58/1080p_View_PTZ_Save.webp 230w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/bc10c/1080p_View_PTZ_Save.webp 460w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/966d8/1080p_View_PTZ_Save.webp 920w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/a5e36/1080p_View_PTZ_Save.webp 1269w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4b4d451ea9a3eed95577e26aba41fc92/81c8e/1080p_View_PTZ_Save.png 230w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/08a84/1080p_View_PTZ_Save.png 460w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/c0255/1080p_View_PTZ_Save.png 920w", "/en/static/4b4d451ea9a3eed95577e26aba41fc92/ee515/1080p_View_PTZ_Save.png 1269w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4b4d451ea9a3eed95577e26aba41fc92/c0255/1080p_View_PTZ_Save.png",
              "alt": "Web User Interface - 1080p Series - Live_Video",
              "title": "Web User Interface - 1080p Series - Live_Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Move your camera to a position, switch the drop-down menu to `}<strong parentName="p">{`Save Position`}</strong>{` and select a preset you want to assign it to.`}</p>
    <p>{`To use the `}<strong parentName="p">{`Scan`}</strong>{` function you can set a start and end point here. The scan will only pan the camera between those points horizontally. The height of the scan is defined by the position the camera is in when you start the scan.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      